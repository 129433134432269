import React from 'react';
import dynamic from 'next/dynamic';

const NotMatchPage = dynamic(() => import('@src/pages/NotMatchPage'), {
  ssr: false,
});

const NotMatch = () => {
  return <NotMatchPage />;
};

export default NotMatch;
